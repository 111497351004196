
import loginMixin from '../mixin/loginMixin';
import SaveBtnGroup from '@/components/Common/SaveBtnGroup.vue';

import { IModifyPwd } from '@/interface/login';
import {
  modifyPwdApi,
  getVerificationCodeApi,
} from '@/api/login';
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';

@Component({
  components: {
    SaveBtnGroup
  }
})
export default class extends Mixins(loginMixin) {
  @Prop({ default: () => [] }) public phoneRegionOptions!: any;
  @Prop({ type: Boolean, default: 'false' }) public dialogVisible?: boolean;

  @Ref() public submitBtnRef!: any;
  @Ref() public modifyPwdFormRef!: any;

  get countDownNumStr() {
    return this.countDownNum + ' s';
  }

  get visible() {
    return this.dialogVisible;
  }
  set visible(newVal) {
    this.$emit('update:dialog-visible', newVal)
  }

  public form: IModifyPwd = {
    mobileAreaCode: '',
    mobile: '',
    verification: '',
    newLoginPs: '',
    confirmPs: '',
    loginId: '',
    fkPlatformType: 'get_issue',
  };
  public countDownNum = 60;
  public countDownFlag = false;

  public rules = {
    mobileAreaCode: [
      {required: true, message: this.$t('login.mobileAreaCodeMsg'), trigger: 'change' }
    ],
    mobile: [
      { required: true, message: this.$t('login.mobileMsg'), trigger: 'blur' },
    ],
    verification: [
      { required: true, message: this.$t('login.verificationMsg'), trigger: 'blur' }
    ],
    newLoginPs: [
      { required: true, validator: this.validatePassword, trigger: 'blur'}
    ],
    confirmPs: [
      { required: true, validator: this.validatePassword, trigger: 'blur' },
      { validator: this.validateConfirmPs, trigger: 'blur' }
    ],
  };

  public validateConfirmPs(rule: any, value: string, callback: (err?: any) => void) {
    if (this.form.newLoginPs && this.form.newLoginPs !== value) {
      callback(new Error(this.$t('login.validPwdMsg') + ''));
    }
    callback();
  }

  // 获取验证码
  public async getVerification() {
    if (!this.checkMobileEmpty()) {
      return;
    }
    const params = {
      type: 'forget',
      mobile: this.form.mobile,
      mobileAreaCode: this.form.mobileAreaCode,
    }
    const { data } = await getVerificationCodeApi(params);
    if (data.code === '1003') {
      this.countDownFlag = true;
      this.setIntervalForVerify();
    }
  }

  // 验证码定时器 60s内不能重复获取
  public setIntervalForVerify() {
    const countDownTimer = setInterval(() => {
      if (this.countDownNum === 1) {
        clearInterval(countDownTimer);
        this.countDownFlag = false;
        this.countDownNum = 60
        return;
      }
      this.countDownNum += -1;
    }, 1000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(countDownTimer);
    })
  }

  // 检查电话或区号是否为空
  public checkMobileEmpty() {
    const mobile = this.form.mobile;
    const areaCode = this.form.mobileAreaCode;
    if (!areaCode) {
      this.$message(this.$t('login.mobileAreaCodeMsg') as string);
      return false;
    }
    if (!mobile) {
      this.$message(this.$t('login.mobileMsg') as string);
      return false;
    }
    return true;
  }

  public submit() {
    this.modifyPwdFormRef.validate((valid: boolean) => {
      if (valid) {
        this.submitBtnRef.loadingTrue();
        this.form.loginId = this.form.mobile;
        modifyPwdApi(this.form).then(({ data }) => {
          if (data.code === '1003') {
            this.$message.success(this.$t('message.modifyPwdSuccess') as string);
            this.$emit('update:dialog-visible', false);
          }
        }).finally(() => {
          this.submitBtnRef.loadingFalse();
          this.clearForm();
        });
      }
    });
  }

  public cancel() {
    this.clearForm();
    this.$emit('update:dialog-visible', false);
  }

  public clearForm() {
    this.modifyPwdFormRef.resetFields();
  }
}
