
import loginMixin from '../mixin/loginMixin';
import LoginBtnComp from './LoginBtnComp.vue';
import LoginTitleComp from './LoginTitleComp.vue';

import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { Form as ElForm } from 'element-ui';
import { IRegister1, IRegister2 } from '@/interface/login';
import {
  getVerificationCodeApi,
  registerApi,
  getAllAreaCountryListApi,
  getByCountryIdApi,
  getByFkAreaStateIdApi,
} from '@/api/login';

@Component({
  name: 'registerFormComp',
  components: {
    LoginBtnComp,
    LoginTitleComp,
  },
})
export default class extends Mixins(loginMixin) {
  @Prop({ default: () => [] }) public countrys!: any;
  @Prop({ default: () => [] }) public phoneRegionOptions!: any;

  @Ref('stepOneForm') public stepOneFormRef!: ElForm;
  @Ref('stepTwoForm') public stepTwoFormRef!: ElForm;

  get countDownNumStr() {
    return this.countDownNum + ' s';
  }

  public loading = false;
  public isStepTwo = false;
  public countDownNum = 60;
  public countDownFlag = false;
  public countryOptions: any = [];
  public stateOptions: any = [];
  public cityOptions: any = [];
  public stepOneFormData: IRegister1 = {
    invitationCode: '',
    mobileAreaCode: '',
    mobile: '',
    verification: '',
    loginPs: '',
    confirmPs: '',
    loginId: '',
  }
  public stepTwoFormData: IRegister2 = {
    name: '',
    company: '',
    fkAreaCountryId: '',
    fkAreaStateId: '',
    fkAreaCityId: '',
    address: '',
    zipCode: '',
    fkPlatformType: 'get_issue',
  };


  // 注册校验
  public stepOneFormRules = {
    invitationCode: [
      { required: true, message: this.$t('login.invitationCodeMsg'), trigger: 'blur' }
    ],
    mobileAreaCode: [
      {required: true, message: this.$t('login.mobileAreaCodeMsg'), trigger: 'change' }
    ],
    mobile: [
      { required: true, message: this.$t('login.mobileMsg'), trigger: 'blur' },
    ],
    verification: [
      { required: true, message: this.$t('login.verificationMsg'), trigger: 'blur' }
    ],
    loginPs: [
      { required: true, validator: this.validatePassword, trigger: 'blur'}
    ],
    confirmPs: [
      { required: true, validator: this.validatePassword, trigger: 'blur' },
      { validator: this.validateConfirmPs, trigger: 'blur' }
    ],
  };

  public stepTwoFormRules = {
    name: [{
      required: true, validator: this.validateInput(this.$t('login.applyPeopleNameMsg') + ''), trigger: 'blur',
    }],
    company: [{
      required: true, validator: this.validateInput(this.$t('login.companyMsg') + '') , trigger: 'blur',
    }],
  };

  public created() {
    this.getAllAreaCountryList();
  }

  public mounted() {
    const query: any = this.$route.query;
    if (query && query.invitcode) {
      this.stepOneFormData.invitationCode = query.invitcode;
    }
  }

  public validateConfirmPs(rule: any, value: string, callback: (err?: any) => void) {
    if (this.stepOneFormData.loginPs && this.stepOneFormData.loginPs !== value) {
      callback(new Error(this.$t('login.validPwdMsg') + ''));
    }
    callback();
  }

  // 获取验证码
  public async getVerification() {
    if (!this.checkMobileEmpty()) {
      return;
    }
    const params = {
      type: 'register',
      mobile: this.stepOneFormData.mobile,
      mobileAreaCode: this.stepOneFormData.mobileAreaCode,
    }
    const { data } = await getVerificationCodeApi(params);
    if (data.code === '1003') {
      this.countDownFlag = true;
      this.setIntervalForVerify();
    }
  }
  // 验证码定时器：60s内不能重复获取
  public setIntervalForVerify() {
    const countDownTimer = setInterval(() => {
      if (this.countDownNum === 1) {
        clearInterval(countDownTimer);
        this.countDownFlag = false;
        this.countDownNum = 60
        return;
      }
      this.countDownNum += -1;
    }, 1000);
    // 组件销毁前清理定时器
    this.$once('hook:beforeDestroy', () => {
      clearInterval(countDownTimer);
    })
  }
  // 检查电话或区号是否为空
  public checkMobileEmpty() {
    const mobile = this.stepOneFormData.mobile;
    const areaCode = this.stepOneFormData.mobileAreaCode;
    if (!areaCode) {
      this.$message(this.$t('login.mobileAreaCodeMsg') as string);
      return false;
    }
    if (!mobile) {
      this.$message(this.$t('login.mobileMsg') as string);
      return false;
    }
    return true;
  }

  // 获取国家
  public async getAllAreaCountryList() {
    if (this.countrys.length > 0) {
      this.countryOptions = this.countrys;
      return;
    }
    const { data } = await getAllAreaCountryListApi();
    this.countryOptions = data.datas;
    this.$emit('get-country-options', data.datas);
  }

  // 获取州省
  public async getByCountryId(id: number) {
    const { data } = await getByCountryIdApi(id);
    this.stateOptions = data.datas;
  }
  // 获取城市
  public async getByFkAreaStateId(id: number) {
    const { data } = await getByFkAreaStateIdApi(id);
    this.cityOptions = data.datas;
  }
  // 国家change事件
  public handleChangeCountry(id: number) {
    this.stateOptions = [];
    this.cityOptions = [];
    this.stepTwoFormData.fkAreaStateId = '';
    this.stepTwoFormData.fkAreaCityId = '';
    this.getByCountryId(id);
  }
  // 州省change事件
  public handleChangeState(id: number) {
    this.cityOptions = [];
    this.stepTwoFormData.fkAreaCityId = '';
    this.getByFkAreaStateId(id);
  }
  // 下一步
  public handleNextStep() {
    this.isStepTwo = true;
    this.stepOneFormRef.validate((valid) => {
      if (valid) {
        this.isStepTwo = true;
      }
    })
  }
  // 上一步
  public handleLastStep() {
    this.isStepTwo = false;
  }
  // 注册
  public handleRegister() {
    this.stepTwoFormRef.validate((valid) => {
      if (valid) {
        this.stepOneFormData.loginId = this.stepOneFormData.mobile;
        const stepOneFormData = this.stepOneFormData
        const stepTwoFormData = this.stepTwoFormData
        delete stepOneFormData.confirmPs;
        const params: any = Object.assign({}, stepOneFormData, stepTwoFormData);
        params.requestUrlType = location.host;
        registerApi(params).then(({ data }) => {
          if (data.code === '1003') {
            this.$emit('to-login');
          }
        })
      }
    })
  }
  // 清除表单校验
  public clearValidate() {
    this.stepOneFormRef.clearValidate();
    this.stepTwoFormRef.clearValidate();
  }
  // 重置表单
  public resetFormData() {
    this.stepOneFormRef.resetFields();
    this.stepTwoFormRef.resetFields();
  }
}
