
import ForgetPasswordComp from './components/ForgetPasswordComp.vue';
import LoginFormComp from './components/LoginFormComp.vue';
import RegisterFormComp from './components/RegisterFormComp.vue';
import OverlayContentComp from './components/OverlayContentComp.vue';

import { Component, Ref, Vue } from 'vue-property-decorator';
import { Form as ElForm } from 'element-ui';
import { getPhoneRegionApi } from '@/api/login';

@Component({
  name: 'login',
  components: {
    LoginFormComp,
    RegisterFormComp,
    OverlayContentComp,
    ForgetPasswordComp,
  },
})
export default class extends Vue {
  @Ref('login-form') public loginForm!: ElForm;
  @Ref('register-form') public registerForm!: ElForm;
  public exflag = false; // 登录注册之间切换标记
  public dialogVisible = false;
  public phoneRegionOptions: any = [];
  public countryOptions: any = [];

  public mounted() {
    const query = this.$route.query;
    if (query && query.invitcode) {
      this.handleSignUp();
    }
    this.getPhoneRegion();
  }

  public handleSignIn() {
    this.exflag = false;
    if (this.registerForm) {
      this.registerForm.clearValidate();
    }
  }

  public handleSignUp() {
    this.exflag = true;
    if (this.loginForm) {
      this.loginForm.clearValidate();
    }
  }

  public handleModifyPwd() {
    this.dialogVisible = true;
  }

  public registerToLogin() {
    this.exflag = false;
  }

    // 获取手机区号
  public async getPhoneRegion() {
    const { data } = await getPhoneRegionApi();
    this.phoneRegionOptions = data.datas;
  }

  public getCountryOptions(datas: any) {
    this.countryOptions = datas || [];
  }
}
