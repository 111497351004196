
import LoginBtnComp from './LoginBtnComp.vue';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'overlayContentComp',
  components: {
    LoginBtnComp,
  },
})
export default class OverlayContentComp extends Vue {
  @Prop({default: ''}) public title!: string;
  @Prop({default: ''}) public subTitle!: string;
  @Prop({default: ''}) public btnName!: string;

  public handleClick() {
    this.$emit('sign-in-or-up');
  }
}
