
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'saveBtnGroup'
})
export default class extends Vue {

  // loading
  public loading: boolean = false;

  public saveFunction() {
    this.$emit('submit-form');
  }

  public cancelFunction() {
    this.$emit('cancel-form');
  }
  // loading为true
  public loadingTrue() {
    this.loading = true;
  }
  // loading为false
  public loadingFalse() {
    this.loading = false;
  }
}
