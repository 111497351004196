import { render, staticRenderFns } from "./LoginTitleComp.vue?vue&type=template&id=3355d62f&scoped=true"
import script from "./LoginTitleComp.vue?vue&type=script&lang=ts"
export * from "./LoginTitleComp.vue?vue&type=script&lang=ts"
import style0 from "./LoginTitleComp.vue?vue&type=style&index=0&id=3355d62f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3355d62f",
  null
  
)

export default component.exports