import { Component, Vue } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import { Input } from 'element-ui';
@Component
export default class LoginMixin extends Vue {

  public passwordType = 'password';

  public validatePassword(rule: any, value: string, callback: (err?: any) => void) {
    if (value.length < 6) {
      callback(new Error(this.$t('login.validPwdNum') + ''));
    } else {
      callback();
    }
  }

  public validateInput(errMsg: string) {
    return (rule: any, value: string, callback: (err?: any) => void) => {
      if (!value.trim()) {
        callback(new Error(errMsg));
      } else {
        callback();
      }
    }
  }
  // 明文显示密码
  public showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = '';
    } else {
      this.passwordType = 'password';
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus();
    });
  }

  // 获取除redirect重定向相关路由信息以外的路由参数对象
  public getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
}
